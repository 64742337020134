import {Ref} from "vue";

type StatesType = {
  [key: string]: Ref<any>;
}
const extractHashFromRefs = (values: StatesType) => {
  const payload: { [key: string]: any} = {};
  
  for (const key in values) {
    payload[key] = values[key].value;
  }
  return payload
}

export {
  extractHashFromRefs
};
