import {Ref} from "vue";

type ErrorMessagesType = Ref<{
  [key: string]: string;
}>

// When using nested forms, it happens that the error come in the form of "user.email" or "user.password"
function getLastElementError(error: string) {
  const errorInArray = error.split(".")
  return errorInArray[errorInArray.length - 1]
}

const setErrorMessages = (errorMessages: ErrorMessagesType, errors: [string]) => {
  for (const error in errors) {
    const lastElementError = getLastElementError(error);
    if (lastElementError in errorMessages.value) {
      errorMessages.value[lastElementError] = errors[error][0];
    }
  }
}

const resetErrorMessages = (errorMessages: ErrorMessagesType) => {
  for (const error in errorMessages.value) {
    errorMessages.value[error] = '';
  }
}

const INVALID_VALUES = ['', [], {}];
type ComputedArgumentsType = Record<string, Ref<string | boolean | undefined | null | [string?]>>

const forbidEmptyValuesOnRef = (values: ComputedArgumentsType): boolean => {
  for (const value in values) {
    const extractedValue = values[value].value;
    if (extractedValue === undefined || extractedValue === null) {
      return false;
    }
    if (INVALID_VALUES.includes(extractedValue.toString().trim())) {
      return false;
    }
  }
  return true;
}

export {
  setErrorMessages,
  resetErrorMessages,
  forbidEmptyValuesOnRef
}
