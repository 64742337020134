

export const snakeToCamel = (obj: any) => {

    const keyObject = Object.keys(obj);
    const newObject: any = {};
    for (const el of keyObject) {
      const newEl = el.replace(/([-_][a-z])/g, (group) => group.toUpperCase()
                                                               .replace('-', '')
                                                               .replace('_', '')
      );
      newObject[newEl] = obj[el]
    }
    return newObject
  };

  const toSnakeCase = (inputString: string) => {
    return inputString.split('').map((character) => {
        if (character == character.toUpperCase()) {
            return '_' + character.toLowerCase();
        } else {
            return character;
        }
    })
    .join('');
  }

export const camelToSnake = (obj: any) => {
  const keyObject = Object.keys(obj);
  const newObject: any = {};
  for (const el of keyObject) {
    const newEl = toSnakeCase(el)
    newObject[newEl] = obj[el]
  }
  return newObject
};


